import axiosDashClient from '../libs/axiosDash'

interface HistoryChangeData {
  id: number
  amountRecordsChanged: number
  dateChange: string
  finishDateChange: string
  startDateChange: string
  organisationName: string
  organisationId: string
}

interface HistoryChangeProps {
  hasMore: boolean
  total: number
  data: HistoryChangeData[]
}

export const getHistoryChange = async (): Promise<HistoryChangeProps> => {
  try {
    const { data } = await axiosDashClient.get('/historicChanges')
    return data
  } catch (error) {
    return {} as HistoryChangeProps
  }
}
