import { Box, CardContent, TablePagination, IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { CardBox, CardBoxSubTitle, CardBoxTitle } from '../../../components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { getHistoryChange } from 'services/history-change.service'

export const AggregateChangesGraph = () => {
  const [dataShowTable, setDataShowTable] = useState<Array<string[]>>([])
  const [headerShowTable, setHeaderShowTable] = useState<string[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')

  const fetchData = async () => {
    try {
      const response = await getHistoryChange()
      const data = response.data.map((item) => [
        item.organisationName,
        `${new Date(item.startDateChange).toLocaleDateString()} à ${new Date(
          item.finishDateChange,
        ).toLocaleDateString()}`,
        new Date(item.dateChange).toLocaleDateString(),
      ])
      setDataShowTable(data)
      sortData(data, 'desc')
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    setHeaderShowTable(['Organização', 'Período alterado', 'Data alteração'])
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const sortData = (data: Array<string[]>, order: 'asc' | 'desc') => {
    const sortedData = [...data].sort((a, b) => {
      const dateA = new Date(a[2].split('/').reverse().join('-'))
      const dateB = new Date(b[2].split('/').reverse().join('-'))
      return order === 'asc'
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime()
    })
    setDataShowTable(sortedData)
  }

  const handleSort = () => {
    const order = sortOrder === 'asc' ? 'desc' : 'asc'
    setSortOrder(order)
    sortData(dataShowTable, order)
  }

  const paginatedData = dataShowTable.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  )

  return (
    <CardBox>
      <CardBoxTitle title="Histórico de alterações" />
      <CardBoxSubTitle title="Histórico de alterações de dados solicitados pelas sociedades participantes" />
      <CardContent>
        <Box
          component="section"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 'auto',
          }}
        >
          <TableContainer sx={{ height: '100%', borderRadius: '0' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headerShowTable.map((column: string, index: number) => (
                    <TableCell
                      key={column}
                      align="left"
                      sx={{
                        fontWeight: 'bold',
                        paddingTop: '6px',
                        paddingBottom: '6px',
                        background: '#fafafa',
                        color: '#0f570d',
                      }}
                    >
                      {column}
                      {column === 'Data alteração' && (
                        <IconButton onClick={handleSort}>
                          {sortOrder === 'asc' ? (
                            <ArrowUpwardIcon fontSize="small" color="primary" />
                          ) : (
                            <ArrowDownwardIcon
                              fontSize="small"
                              color="primary"
                            />
                          )}
                        </IconButton>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.length > 0 ? (
                  paginatedData.map((row: Array<string>, rowIndex: number) => (
                    <TableRow key={rowIndex}>
                      {row.map((column: string, columnIndex: number) => (
                        <TableCell
                          key={`${rowIndex}-${columnIndex}`}
                          align={'left'}
                          sx={{
                            maxWidth: '170px',
                            overflowWrap: 'break-word',
                          }}
                        >
                          {column.toUpperCase()}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow key={'no-item'}>
                    <TableCell key={'no-item-1'} align={'left'}>
                      Nenhum dado a ser exibido!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {dataShowTable.length > 10 && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={dataShowTable.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </TableContainer>
        </Box>
      </CardContent>
    </CardBox>
  )
}
